* {
  scrollbar-width: none; 
}

*::-webkit-scrollbar {
  display: none; 
}

.home {
    display:flex; 
    flex-direction:column; 
    justify-self:center; 
    width: 100%; 
    overflow-x: hidden;
}

.gradientHeader {
    text-transform: uppercase;
    background: linear-gradient(180deg, #E60000 0%, rgba(156, 42, 160, 0.611765) 65%);;
    background-clip: text;
    color: transparent;    
    font-size: 48px;
    letter-spacing: 8px;
    padding: 0px 0px 15px;
    text-align: left;
    font-weight: 600;
}

.link, .link:link, .link:hover, .link:visited, .link:active {
    text-decoration: none;
    color: black;
}

.hamMenu {
    display: none;
}

.ribbon {
    position: relative;
    z-index: 2;
}

.smallRibbon {
    position: relative;
    display: none;
    z-index: 2;
}

.whiteDiamond {
    width: 14px;
    height: 14px;
}



@media only screen and (max-width: 600px) {

    .smallRibbon {
        display: block;
    }

    .ribbon {
        display: none;
    }

    .hamMenu {
        display: block;
    }

    .gradientHeader {
        font-size: 32px;
        letter-spacing: 4px;
        text-align: center;
    }

    .whiteDiamond {
        width: 10px;
        height: 10px;
    }
}

