.countdown-renderder-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.time-unit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: 1rem;
  margin-bottom: 0px;
  border: 1px solid #e60000;
  border-radius: 16px;
  opacity: 1;
  width: 9.4rem;
  height: 10rem;
  margin-top: 0.5px;
}

.countdown-number-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    46px / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 46px/40px Vodafone Rg;
  letter-spacing: 4.6px;
  color: #e60000;
  text-transform: uppercase;
}

.countdown-number-sub {
  letter-spacing: var(--unnamed-character-spacing-2-8);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: 28px/40px Vodafone Rg;
  letter-spacing: 2.8px;
  color: #e60000;
  text-transform: uppercase;
}

.timer-colon {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.colon-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #e60000;
  opacity: 1;
}

@media only screen and (max-width: 991.98px) {
  .countdown-number-title {
    font-size: 1.3rem;
    letter-spacing: 0.13rem;
    line-height: 1.125rem;
  }

  .countdown-number-sub {
    font-size: 0.8rem;
    letter-spacing: 0.08rem;
    line-height: 1.063rem;
  }
}
