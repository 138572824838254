.consentHeader,
.consentDetails {
  text-align: center;
}

.consentContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 70vh;
  overflow-y: scroll;
  align-items: center;
}

.consentContainer::-webkit-scrollbar {
  width: 3px;
}

.consentContainer::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 0px;
}

.consentContainer::-webkit-scrollbar-thumb {
  background: rgba(184, 184, 184, 1);
  border-radius: 10px;
}

.consentDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: white;
  border: 1px solid rgba(184, 184, 184, 1);
  padding: 25px;
  direction: rtl;
  width: 92%;
  align-self: center;
  line-height: 20px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userInfoItem > label {
  margin: 0;
}

.consentCheck {
  display: flex;
  gap: 10px;
  align-items: center;
  align-self: flex-start;
  margin-top: -10px;
}

.consentCheck {
  display: flex;
  gap: 0px;
  align-items: center;
  align-self: flex-start;
  padding-left: 8px;
}

.consentCheck > label {
  margin: 0;
}

.btnsGp {
  display: flex;
  gap: 20px;
  align-self: flex-start;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .btnsGp {
    gap: 5px;
    justify-content: flex-end;
    padding-right: 20px;
  }
}