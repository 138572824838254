.themeContainer {
    display: flex;
    gap: 20px;
    padding: 20px 20px;
    align-items: center;
    justify-content: flex-start;
}

.themeStatementContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.themeStatement {
    color: black;
    font-size: 32px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.themeHeader {
    width: 70%;
}

.themeDetails {
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 27.55px;
    text-align: justify;
    width: 95%;
    text-indent: 0;
}

@media only screen and (max-width: 600px) {

    .themeContainer {
        background-image: url('/public/assets/2025/home/sunset.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x:center;
        color: white;
        padding: 30px 20px 100px;
    }

    .themeStatementContainer {
        align-items: center;
    }

    .themeHeader {
        color: white !important;
        font-size: 24px !important;
        text-align: center;
        width: 100%;
    }

    .themeStatement {
        color: white !important;
        font-size: 20px;
        align-self: flex-start;
    }

    .themeDetails {
        font-size: 16px;
        line-height: 18.37px;
        letter-spacing: 0;
        align-self: flex-start;
    }

    .sunsetImg {
        display: none;
    }
}