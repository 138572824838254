.before-session-container {
  min-height: 100vh;
  background-color: white;
  width: 94%;
}

.countdown-container, .before-session-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.before-session-msg {
    color: black;
}
