input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

.createAccountFormContainer,
.inputFieldContainer {
  display: flex;
  flex-direction: column;
}

.createAccountFormContainer {
  height: 70vh;
  overflow-y: scroll;
}
.createAccountFormContainer::-webkit-scrollbar {
  width: 3px;
}

.createAccountFormContainer::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 0px;
}

.createAccountFormContainer::-webkit-scrollbar-thumb {
  background: rgba(184, 184, 184, 1);
  border-radius: 10px;
}

.inputFieldContainer {
  justify-content: flex-start;
  padding-left: 20px;
  margin-bottom: 13px;
}

.inputLabel {
  margin: 0;
  font-size: 14px;
}
.inputField {
  width: 95%;
  border: none;
  border-bottom: 1px solid rgba(184, 184, 184, 1);
  padding: 8px 10px;
}

/* Password field styles */
.passwordFieldWrapper {
  position: relative;
  width: 95%;
  display: flex;
  align-items: center;
}

.passwordField {
  width: 100%;
  padding-right: 60px; /* Make room for the button */
}

.passwordToggleBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #e60000; /* Vodafone red */
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  transition: opacity 0.3s ease;
}

.passwordToggleBtn:hover {
  opacity: 0.8;
}

.passwordToggleBtn:focus {
  outline: none;
}

/* Ensure cross-browser compatibility */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.inputField::placeholder {
  color: rgba(184, 184, 184, 1);
  font-size: 16px;
}

.inputFieldBtn {
  cursor: pointer;
  position: absolute;
  top: 26px;
  padding: 0;
  right: 40px;
  border: none;
  border-bottom: 1.5px solid #e60000;
  color: #e60000;
  font-size: 16px;
  letter-spacing: 0.5px;
  background-color: transparent;
}

.fieldErrorMsg {
  color: #e60000;
}

.clickVerify {
  font-size: 12px;
  color: rgba(114, 114, 114, 1);
  margin: -8px 20px 10px;
}
.clickVerifyMob {
  display: none;
  font-size: 12px;
  color: rgba(114, 114, 114, 1);
  margin: -8px 20px 10px;
}

.inputFieldWithBtnContainer {
  position: relative;
  flex-basis: 64%;
}

.verCodeFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 33%;
}

.verifyDiv {
  width: 63.5%;
}

.succVerStatusMsg,
.failVerStatusMsg {
  padding-left: 20px;
  margin-top: -10px;
}

.succVerStatusMsg {
  color: green;
}

.failVerStatusMsg {
  color: #e60000;
}

.toBeVerifiedContainer {
  display: flex;
  flex-direction: column;
}
.toBeVerifiedFieldContainer {
  display: flex;
  align-items: flex-start;
}

.toBeVerifiedFieldContainer
  > .verifyDiv
  > .inputFieldWithBtnContainer
  > .inputFieldBtn {
  right: 25px;
}

.verCodeFieldContainer > .inputFieldWithBtnContainer > .inputFieldBtn {
  right: 17px;
}

.btnsGp {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .toBeVerifiedFieldContainer > div > .inputFieldWithBtnContainer,
  .verCodeFieldContainer {
    width: 100%;
  }

  .toBeVerifiedFieldContainer
    > div
    > .inputFieldWithBtnContainer
    > .inputFieldBtn {
    right: 23px;
  }
  .toBeVerifiedFieldContainer {
    flex-direction: column;
  }

  .clickVerify {
    display: none;
  }

  .clickVerifyMob {
    display: block;
  }

  .btnsGp {
    gap: 5px;
    justify-content: flex-end;
    margin-right: 20px;
  }
  .verifyDiv {
    width: 100%;
  }
}
