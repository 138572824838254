.vois-logo {
  margin: 1rem 0 0 0;
  width: 83.824px;
  height: 20.902px;
}

.hackathon-logo {
  width: 193px;
  height: 25px;
  margin: 1rem 0 1rem 0;
}
