.time {
  padding: 1rem;
  width: 5rem;
  color: black;

  height: 53px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  padding-bottom: 10px;
  border-right: 1.3px black solid;
}
.speaker-div {
  color: black;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
.speaker {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.title {
  color: black;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.agenda-item:nth-child(even) {
  color: black;
  background: rgba(184, 184, 184, 0.4);

  height: 77px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.agenda-item:nth-child(odd) {
  color: black;
  background: white;
  display: flex;
  flex-direction: row;
  height: 77px;
  width: 100%;
  align-items: center;
}