.SSContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.header {
  font-weight: 550;
  margin-top: 40px;
  color: #e60000;
  margin-bottom: 20px;
}

.details {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.actionBtn {
  background-color: #e60000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  letter-spacing: 0.8px;
  display: flex;
gap: 8px;  align-items: center;
}
