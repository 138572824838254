.footerContainer, .footerLinks, .socialMediaLinks {
    display: flex;
    justify-content: space-between;
}

.footerContainer {
    width: 97%;
    align-self: center;
    padding: 20px 10px;
    border-top: 1px solid #E60000;
}

.footerLinks {
    width: 15%;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 1px;
}

.socialMediaLinks {
    width: 10%;
}

@media only screen and (max-width: 600px) {
    .footerContainer{
        margin-top: -65px;
        background-color: transparent;
        justify-content:end;
        border-top: 1px solid rgba(255, 255, 255, 1);
    }
    .socialMediaLinks {
        gap: 5px;
        width: 30%;
    }
    .footerLinks {
        display: none;
    }
}