.pitchFormContainer {
  height: 70vh;
  overflow-y: scroll;
}
.pitchFormContainer::-webkit-scrollbar {
  width: 3px;
}

.pitchFormContainer::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 0px;
}

.pitchFormContainer::-webkit-scrollbar-thumb {
  background: rgba(184, 184, 184, 1);
  border-radius: 10px;
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  margin-bottom: 13px;
}

.fieldArrayContainer {
  display: flex;
  width: 100%;
}

.fieldArrayContainer > .inputFieldContainer {
  width: 89%;
}

.inputLabel {
  margin: 0;
  font-size: 14px;
}

.inputField {
  width: 95%;
  border: none;
  border-bottom: 1px solid rgba(184, 184, 184, 1);
  padding: 8px 10px;
}

.fieldErrorMsg {
  color: #e60000;
}

.uploadPresentFile {
  text-align: center;
  vertical-align: middle;
  width: 95%;
  padding: 20px 50px;
  background-color: white;
  color: rgba(184, 184, 184, 1);
  border: 1.2px dashed rgba(184, 184, 184, 1);
}

.enteredItems {
  display: flex;
  gap: 10px;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
}

.enteredItem {
  background-color: #e60000;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  margin-top: 10px;
}

.enteredItem:first-child {
  margin-left: -40px;
}

.btnsGp {
  display: flex;
  gap: 20px;
  align-items: center;
}

.pressEnter, .uploadSizeInstruction {
  color: rgba(114, 114, 114, 1);
  font-size: 14px;
  list-style: circle rgba(114, 114, 114, 1);
  margin-top: -25px;
}

.uploadSizeInstruction {
  margin-top: -10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .btnsGp {
    gap: 5px;
    justify-content: flex-end;
    margin-right: 20px;
  }
}
