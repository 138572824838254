.countryLabel {
  text-transform: capitalize;
  font-size: 20px;
}

.countryLabel:hover {
  color: #e60000;
}

.regionFCL,
.TCChecks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regionFCL {
  margin-left: 20px;
  border-bottom: 1px solid rgba(184, 184, 184, 1);
}

.regionFCL:last-child {
  border-bottom: none;
}

.TCChecks {
  padding-left: 8px;
}

.radioError {
  color: #e60000;
  padding: 0px 20px 10px;
}

.link,
.link:link,
.link:hover,
.link:visited,
.link:active {
  color: black;
  text-decoration: underline;
}

.getStartedContainer {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(246, 246, 246, 1);
  height: 100vh;
}

.getStartedFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 500px;
}
.getStartedForm {
  display: flex;
  flex-direction: column;
}
.getStartedHeader {
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px 20px;
}

.getStartedStatement {
  font-size: 18px;
  padding-left: 20px;
  padding-bottom: 20px;
  line-height: 26px;
}

.VOISSpan {
  color: #e60000;
  font-weight: 600;
  text-align: justify;
}


@media only screen and (max-width: 600px) {
  .TCChecks {
    flex-direction: column;
    margin-top: 30px;
    align-items: flex-start;
  }
  
  .regionFCL {
    margin-right: 20px;
  }
}