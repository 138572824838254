.footer-main-div {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  /* border: #FFF 1px solid; */
  align-items: center;
  flex-direction: column;
  /* height: 5rem; */
  z-index: 100000000;
  bottom: 2vh;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11%;
  padding: 0px 0.5rem;
  margin-top: 1rem;
  width: 1206px;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-section-content {
  color: black;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-right: 1.5vw;
}

.left-section-content:hover {
  color: black;
  text-decoration: none;
}

.right-section-content {
  width: 23.998px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
}