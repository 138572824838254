.processContainer {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url("/public/assets/2025/home/processBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

  z-index: 1;

  height: 500px;
  overflow-y: scroll;

  padding-bottom: 100px;
}


.processSecHeader {
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 10px;

  padding: 50px 0px;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
}

.stepContainer {
  display: flex;
  align-self: center;
}

.stepOrder {
  font-size: 18px;
  position: absolute;
  margin: auto 0;
  margin-top: -5x;
  letter-spacing: 5px;
}

.stepHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepHeader {
  display: flex;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 32px;
  align-items: baseline;
  gap: 6px;
  margin-top: -70px;
}

.stepDetails {
  width: 29%;
  position: absolute;
  margin-top: -6px;

  font-size: 16px;
  font-weight: 100;
  line-height: 22.96px;
  text-align: justify;
  color: rgba(255, 255, 255, 0.65);
}

.stepContainer:nth-child(1) > .stepDetails {
  padding-left: 45px;
}

.stepContainer:nth-child(3) > .stepDetails {
  padding-right: 45px;
}

.stayTunedBlackDiamond {
  background-color: black;
  rotate: 45deg;
  width: 245px;
  height: 245px;
  border: 1.5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 32px;
}

.stayTunedStatement {
  width: 30%;
  text-align: justify;
  margin-top: 70px;
  color: rgb(255, 255, 255, 0.7);
}

.processRect {
  width: 370px;
}

.processRectMob {
  display: none;
}

.verticalLine {
  background-color: white;
  width: 1px;
  height: 100px;
  margin-top: -9px;
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .processContainer {
    height: auto;
  }

  .processSecHeader {
    font-size: 24px;
  }
  .stepContainer {
    flex-direction: column !important;
    align-items: center;
  }

  .stepOrder {
    font-size: 20px !important;
    letter-spacing: 4px;
  }

  .stepHeader {
    font-size: 16px !important;
    font-weight: 400;
    letter-spacing: 2px;
    margin-top: -43px;
    gap: 10px;
  }

  .stepDetails {
    position: static;
    width: 88%;
    margin: auto;
    margin-top: 30px;
    font-size: 12px;
    line-height: 14px;
  }

  .stepContainer:nth-child(1) > .stepDetails {
    padding-left: 0;
  }

  .stepContainer:nth-child(3) > .stepDetails {
    padding-right: 0;
  }

  .processRect {
    display: none;
  }

  .processRectMob {
    display: block;
    width: 265px;
  }

  .stayTunedBlackDiamond {
    width: 189px;
    height: 189px;
    font-size: 16px;
  }
  .stayTunedStatement {
    font-size: 16px;
    line-height: 19px;
    width: 88%;
  }
}



@media only screen and (min-width: 1250px) {
  .stepContainer:nth-child(1) > .stepDetails {
  padding-left: 0;
}

.stepContainer:nth-child(3) > .stepDetails {
  padding-right: 0;
}
}
