.main-agenda-bg {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-div-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 93%;
}

.dates-list {
  border: 1px solid #e60000;

  float: left;
  /* min-height: 280px; */
  list-style-type: none;
  padding: 0;
}

.dates {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  width: 30%;
  max-height: 55vh;
  overflow-y: auto;
  border-bottom: 1px solid #e60000;
}

.dates::-webkit-scrollbar {
  width: 0px;
}

.agenda {
  /* margin-top: 2rem; */
  /* border: 1px solid #e60000; */
  width: 75vw;
  height: fit-content;
  max-height: 55vh;
}

.agenda-list {
  float: left;
  height: 100%;
  border: 1px solid #e60000;
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: 65vh;
  overflow: auto;
}

.agenda-header {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-left: 45px;
  margin-bottom: 5px;
  gap: 10px;
  font-size: 32px;
  text-transform: uppercase;
}
