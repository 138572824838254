.joinContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(242, 242, 242, 1);
  padding: 0px 0px 50px;
}

.joinStatement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.joinStatementText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.joinStatementDetails {
    font-size: 20px;
    text-align: justify;
    line-height:24.2px;
    word-spacing: 10px;
    width: 82%;
}

.actionBtn {
  background-color: var(--text-secondary);
  color: white;
  padding: 8px 28px;
  border: none;
  margin-top: 20px;
}

.actionBtn:hover {
  box-shadow: 0px 0px 19.6px 0px var(--text-secondary);
}


@media only screen and (max-width: 600px) {
  .joinContainer {
    background: linear-gradient(180deg, rgba(114, 114, 114, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),url('/public/assets/2025/home/man_mob.jpg');
    background-size: cover;
    background-position-x: center;

    padding: 55px 0px;
  }

  .joinStatement > img {
    display: none;
  }

  .joinHeader {
    font-size: 24px !important;
    color: white !important;
    letter-spacing: 3px !important;
  }

  .joinStatementText {
    color: white;
    font-size: 16px;
    align-items: center;
  }

  .joinStatementDetails {
    font-size: 16px;
    line-height: 19.37px;
  }
}
