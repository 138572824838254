.timelineContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('/public/assets/2025/home/lamp.png');
    background-attachment: fixed;
    background-size: cover;

    padding: 80px 0px;
}

.HackathonPhases {
    width: 45%;
    margin-top: 20px;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 60px;
}

.phaseStartDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.phaseStartDateSquare {
    width: 85px;
    height: 85px;

    background: linear-gradient(225deg, #E60000 0%, rgba(156, 42, 160, 0.611765) 100%);
    rotate: -45deg;

    padding: 20px;
}

.startDateText {
    font-size: 16px; 
    font-weight: 500;
    rotate: 45deg; 
    text-align: center;
    color: white;
    letter-spacing: 2px;
}

.HackathonPhase {
    display: flex;
    gap: 70px;
    flex-basis: 200px;
    align-items: center;
}

.phaseNameAndStatement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 400px;
}

.phaseName {
    text-transform: uppercase;
    color: var(--text-secondary);
    font-size: 32px;
    letter-spacing: 2px;
}

.phaseStatement {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
}

.verticalLine {
    position: absolute;
    margin-top: 130px;
    height: 100px;
    width: 0.5px;
    background-color: rgba(192, 119, 190, 1);
}

@media only screen and (max-width: 600px) {
    .timelineContainer {
        padding: 50px 0px 25px;
    }

    .HackathonPhases {
        width: 60%;
        gap:5px;
    }

    .HackathonPhase {
        gap:20px;
    }

    .phaseStartDateSquare {
        width: 48px;
        height: 48px;
        padding:10px;
    }

    .startDateText {
        font-size: 11px;
    }

    .verticalLine {
        margin-top: 57.5px;
        height: 120px;
    }
    .phaseName {
        font-size: 16px;
        letter-spacing: 1px;
    }
    .phaseStatement {
        font-size: 14px;
    }
}