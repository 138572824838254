.judgesContainer {
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 55px 0px 65px;
}

.HackathonJudges {
  width: 93%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 40px 0px;
}

.judge {
  background-color: rgba(242, 242, 242, 1);
  background-position-x: center;

  height: 355px;
  width: 300px;
  padding: 20px;
  font-size: 30px;
  text-align: center;

  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.judge:nth-child(n) {
  border-right: 1.5px solid red;
  border-bottom: 1.5px solid red;
}
.judge:nth-child(4n) {
  border-right: none;
}

.judge:nth-last-child(-n + 2) {
  border-bottom: none;
}

.judgeInfo {
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  padding: 5px 60px;
  border: 1px solid red;
}

.judgeName {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

.judgeTitle {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .HackathonJudges {
    grid-template-columns: repeat(20, 1fr);
    overflow-x: scroll;
  }

  .judge:nth-child(4n) {
    border-right: 1px solid red;
  }

  .judge:nth-last-child(-n + 2) {
    border-bottom: 1px solid red;
  }
  .judge:last-child {
    border-right: none;
  }
}
