.session-navbar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 96%;
}
.logos-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vois-logo {
  margin: 1rem 0 0 0;
  width: 83.824px;
  height: 20.902px;
}

.back-btn {
  display: flex;
  align-items: center;
  gap: 2px;
  padding-bottom: 10px;
  font-size: 22px;
  cursor: pointer;
}

.breadcrumb {
  display: flex;
  gap: 8px;
  align-self: flex-start;
  margin-left: 5%;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 16px;
}

.breadcrumb-hall {
  color: black;
  font-weight: 600;
}

.breadcrumb-text {
  color: #999999;
}

.breadcrumb-hall:hover {
  color: black;
  text-decoration: none;
}
