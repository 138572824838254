.coaches-box {
  /* border-radius: 6px; */
  border: 1px solid #e60000;
  border-bottom: none;
  float: left;
  list-style-type: none;
  /* background-color: rgba(0, 0, 0, 0.43); */
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
}
.coaching-sessions-title {
  color: black;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}
.coaching-sessions-dates {
  color: black;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 0.5rem;
}
.coaching-sessions-btn {
  color: #e60000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}