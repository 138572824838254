.agenda-list {
  z-index: 10;
  background: white;
  border: none;
  padding: 0;
  margin: 0;
  min-height: 65vh;
  max-height: 70vh;
  overflow: auto;
  top: 10%;
}

.agenda-header {
  display: flex;
  align-items: center;
}

.agenda-item:nth-child(even) {
  background: #f1f1f1;
  min-height: 77px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.agenda-item:nth-child(odd) {
  background: white;

  display: flex;
  flex-direction: row;
  min-height: 77px;
  width: 100%;
  align-items: center;
}

.time {
  padding: 4px 24px;
  width: 5rem;
  color: #e60000;
  height: 50px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-right: 1px rgba(82, 82, 82, 1) solid;
}

.speaker-div {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  width: 60%;
}

.speaker {
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.title {
  color: #000;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.agenda-title {
  color: black;
  text-transform: uppercase;

  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.agenda-list-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  background: #f1f1f1;
  padding: 16px 8px;
}
.date-in-agenda {
  color: #525252;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* text-decoration-line: underline; */
  padding: 4px 6px;
  border: #525252 1px solid;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.arrow-down {
  margin-left: 0.5rem;
}

.dropdown-menu {
  width: 10%;
}

.dropdown-item {
  font-size: 16px;
  line-height: 28px;
  display: block;
  padding-left: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.dropdown-item:hover {
  background-color: #e60000;
  color: #fff !important;
}

.slido-container {
  display: flex;
  width: 25%;
  height: 60vh;
  margin-top: 20px;
  opacity: 1;
  transition: width 0.5s ease-in-out, opacity 0.5s ease-out;
}

.slido-container.spread {
  margin-top: 20px;
  width: 0;
  opacity: 0;
}
