.registerContainer {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(246, 246, 246, 1);
  height: 100vh;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 500px;
}

.formHeader {
  font-size: 28px;
  font-weight: 600;
  padding: 0px 20px;
}

.formStatement {
  font-size: 18px;
  padding: 20px 20px 10px;
  line-height: 26px;
}

