.dates-list {
  border: 1px solid #e60000;
  
  float: left;
  /* min-height: 280px; */
  list-style-type: none;
  padding: 0;
}
.dates {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem; */
  width: 30%;
  max-height: 55vh;
  overflow-y: auto;
  border-bottom: 1px solid #e60000;
}

.dates::-webkit-scrollbar {
  width: 0px;
}

.date-item.selected {
  /* border-radius: 6px 6px 0px 0px; */
  color: white;
  background: #d60101;
  height: 100px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
  justify-content: center;
}
.date-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  height: 100px;
  padding: 0 0.75rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.day {
  color: black;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
}
.day.selected {
  color: white;
}
.weekday {
  color: black;
  text-transform: uppercase;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}

.weekday.selected {
  color: white;
}
.weekdate {
  color: black;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}

.weekdate.selected {
  color: white;
}