.navBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.navBar {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 0px;
    align-items: center;
}

.VOISLogo {
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 3px;
}

.navLinksContainer {
    display: flex;
    justify-content: space-between;
    width: 30%;
    font-size: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .navBarContainer {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
        padding: 5px 0px 10px;
    }

    .VOISLogo {
        font-size: 32px;
    }

    .navLinksContainer {
        position: absolute;
        background: linear-gradient(180deg, rgba(230, 0, 0, 0.8) 0%, rgba(157, 42, 159, 0.8) 100%);
        width: 100%;
        top: 68px;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .navLink {
        font-size: 18px;
        border-bottom: 1px solid white;
        padding: 10px 0px;
        font-weight: 400;

    }

    .navLink:last-child {
        font-weight: 600;
        color: white !important;
        border-bottom: none !important;
        padding-bottom: 0;
    }


}