.prizeContainer {
    display: flex;
    flex-direction: column;
    width: 93%;
    background: linear-gradient(270deg, rgba(230, 0, 0, 0) 0%, #9D2A9F 74.5%), url('/public/assets/2025/home/windmills.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    align-self: center;

    padding: 70px 50px;
    color: white;

    margin-bottom: 60px;
}

.prizeHeader {
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: 48px;
    font-weight: 500;
    line-height: 55.5px;
    margin-bottom: 40px;
}

.paragraph, .prizes, .runnerUps {
    width: 60%;
}

.paragraph {
    font-size: 20px;
    line-height: 23px;
    text-align: justify;
    word-spacing: 5px;
    word-break: break-word;
}

.paragraph:nth-child(3) {
    padding-bottom: 30px;
}

.prizes, .winner {
    display: flex;
    flex-direction: column;
}

.prizes {
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding: 20px 0px;
}

.winner {
    justify-content: center;
    align-items: center;
    background-size:contain;
    background-repeat: no-repeat;
    background-position-x: center;
}

.winnerPlaceText {
    font-weight: 100; 
    letter-spacing: 4px; 
    font-size: 16px;
}

.prizeDetailsPerPlace {
    display: flex; 
    align-items: center;
    gap: 10px;
}

.runnerUps {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.runnerUps > .winner:nth-child(1), .winner:nth-child(2) {
    border-left: 1px solid white;
    flex-basis: 50%;
}

.runnerUps > .winner:nth-child(2) {
    border-right: 1px solid white;
}


@media only screen and (max-width: 600px) {
    .prizeContainer {
        width: 100%;
        padding: 50px 20px;
        margin-bottom: 0px;
        background: linear-gradient(270deg, rgba(230, 0, 0, 0.2)20%, #9D2A9F 70%), url('/public/assets/2025/home/windmills2.jpg');
        background-position-x:left;
        background-position-y: bottom;
    }

    .prizeHeader {
        font-size: 24px;
        letter-spacing: 4px;
        text-align: center;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .paragraph, .prizes, .runnerUps {
        width: 100%;
    }

    .paragraph {
        font-size: 16px;
        line-height: 18.5px;
    }

    .runnerUps {
        flex-direction: column;
        padding: 0px;
    }

    .runnerUps > .winner:nth-child(1), .winner:nth-child(2) {
        border-left: none;
        padding: 20px 0px;
    }

    .runnerUps > .winner:nth-child(2) {
        border-right: none;
        border-top: 1px solid white;
    }
}

