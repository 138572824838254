.submitBtn {
  width: 33%;
  background-color: #e60000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
}

.goBackBtn {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  padding: 10px 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
}

.addBtn {
  cursor: pointer;
  width: 39px;
  height: 39px;
  background-color: #e60000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-left: -10px;
}

.statementSpan {
  color: #e60000;
  font-weight: 600;
  text-align: justify;
}

.logoConatiner {
  display: none;
}

@media only screen and (max-width: 600px) {
  .submitBtn {
    width: 48%;
    margin-right: 20px;
    align-self: flex-end;
  }

  .logoConatiner {
    display: block;
    justify-content: flex-start;
    padding: 0px 20px;
    margin-bottom: -25px;
    background-color: rgba(246, 246, 246, 1);
  }

  .VOISLogo {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 3px;
}
}


