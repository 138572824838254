.toggle-sound {
  position: absolute;
  top: 5%;
  right: 4rem;
  /* background-color: black; */
  /* width: 5%; */

  text-align: center;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #f06292;
}

.toggle-Next {
  position: absolute;
  top: 5%;
  left: 4rem;
  text-align: center;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #f06292;
  transform: rotate(180deg);
}

.Next--icon {
  color: white;
  line-height: inherit;
  font-size: 2rem;
  display: block;
  margin: auto;
  text-align: center;
  margin-left: 1rem;

  margin-top: -2%;
  top: 0;
}

.toggle-sound.sound-mute {
  box-shadow: none;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

.sound {
  width: 97%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  display: inline-block;
  left: 0;
  top: -3;
  margin-left: -15%;
}

.sound--icon {
  color: inherit;
  line-height: inherit;
  font-size: 1.6rem;
  display: block;
  margin: auto;
  text-align: left;

  margin-top: -15%;
  top: 0;
}

@media screen and (min-width: 581px) {
  .sound--icon,
  .Next--icon {
    padding-left: 25%;
  }
}

.sound--wave {
  position: absolute;
  border: 2px solid transparent;
  border-right: 2px solid #fff;
  border-radius: 50%;
  transition: all 200ms;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sound--wave_one {
  width: 45%;
  height: 40%;
}

.sound--wave_two {
  width: 70%;
  height: 62%;
}

.sound--wave_three {
  width: 95%;
  height: 75%;
}

.fullScreenBackground {
  width: 100vw;
  height: 120vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(230, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #e60000;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}