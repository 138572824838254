* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Webkit (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none;
}

.session-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.session-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.live-streamer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stream-loading-progress {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.live-streamer {
  border: 2px solid #e60000;
  margin-bottom: 8px;
  margin-top: 20px;
  margin-left: 8px;
  width: 98%;
}

.show-agenda-btn {
  background-color: transparent;
  color: white;
  border-radius: 0px 10px 0 0;
  border: none;
  position: relative;
  left: -0.5%;
  margin-top: 1.4%;
  height: 50px;
}

.show-agenda-btn:hover {
  display: none;
  cursor: default;
}

@media (min-height: 610px) {
  .session-container {
    height: 100vh;
  }
}
