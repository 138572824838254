.heroSection {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("/public/assets/2025/home/windmills.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}

.heroContent {
  max-width: 800px;
  text-align: center;
  padding: 0 20px;
  z-index: 2;
}

.heroTitle {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 8px;
  font-weight: 600;
  background: linear-gradient(
    180deg,
    #e60000 0%,
    rgba(156, 42, 160, 0.611765) 65%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.heroSubtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-weight: 300;
  color: white;
}

.createButton {
  background: linear-gradient(180deg, #e60000 0%, rgba(156, 42, 160, 0.8) 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(230, 0, 0, 0.4);
}

.createButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(230, 0, 0, 0.6);
}

.energyIcon {
  position: absolute;
  opacity: 0.15;
}

.windTurbine {
  bottom: -50px;
  left: 10%;
  width: 200px;
  height: 200px;
}

.solarPanel {
  top: 20%;
  right: 10%;
  width: 150px;
  height: 150px;
}

.leaf {
  top: 30%;
  left: 15%;
  width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 2.5rem;
  }

  .heroSubtitle {
    font-size: 1.2rem;
  }

  .createButton {
    padding: 12px 24px;
    font-size: 1rem;
  }
}
