.imageryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.floatingBox {
  position: absolute;
  width: 350px;
  height: 280px;
  margin-left: -5px;
  margin-bottom: -6.7px;

  background: linear-gradient(
    180deg,
    #e60000 0%,
    rgba(156, 42, 160, 0.611765) 100%
  );
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fadeImage {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.loaded {
  opacity: 1;
}

@media only screen and (max-width: 600px)  {
  .imageryContainer {
    display: none;
  }
}