.footer-main-div {
  margin: 8px 20px;
  width: 93.3%;
  border-top: 1px solid #e60000;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-section-content {
  color: black;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-right: 1.5vw;
}

.left-section-content:hover {
  color: black;
  text-decoration: none;
}

.right-section-content {
  width: 23.998px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
}
